import React from 'react'
import Navbar from './Navbar'
import './Post.css'

function MyProfile() {
  return (
    <div>
      <div className='post-container'>

        <div className='white-container'>
          <div className='contents-container'>
            <h1 id='top'>About me</h1>

            <section>
              <h2>Who am I?</h2>
              <p>
                My name is Julio Macias Gonzalez, born and raised in Spain. I have always been passionate about learning, particularly in the realm of technology. In my free time, I enjoy going to the gym, travelling, and occasionally playing video games. I make it a point to stay up to date with the latest advancements in technology.
              </p>
            </section>

            <section>
              <h2>Why did I decide to study Computer Science?</h2>

              <div className='txtAndPic'>
                <div className='mr-12 ml-12'>
                  <img src='./images/computer.png' className='object-cover rounded-xl' id='computer' alt='first desktop computer picture' />
                  <center><p id='caption'><em>Caption: First self-assembled computer in 2020.</em></p></center>
                </div>
                <div>
                <p>My journey with technology began in 2014 when I received a gift, a PS4. However, as gaming consoles often struggled to deliver good performance, my curiosity about computers sparked, getting a Windows gaming laptop a year later. When dealing with a computer, often, you run into problems such as the infamous Windows blue screen, the enjoyment while solving these challenges has driven my journey.</p>
                </div>
              </div>
              <br />
              <p>Because of all this, when the time came to make a choice, I knew that Computer Science was the way forward for me.</p>
            </section>

            <section>
              <h2>Academic Background</h2>

              <p>In 2019, I had the opportunity to study the equivalent of Year 11 in Galway, Ireland. I finished the last two years of school at Sunland Novaschool, a British School in Malaga, and decided to move to Belfast afterward to study Computer Science at Queen’s University Belfast.</p>
            </section>

            <section id='bottom'>
              <h2>Working Experience</h2>
              <p>I gained experience working in a summer office job in 2019 and worked as a waiter in the summer of 2023. Served as catering and floor assistant at Spar in Queen’s University Belfast for 9 months. <br/> <br/> Currently placed as Technology Operations Intern in Citi.</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyProfile;
