import React from 'react';
import '../../App.css';
import ProjectsSection from '../ProjectsSection';
import Footer from '../Footer'

function Projects () { 
    return(
        <>
<ProjectsSection/>
        </>
    );
}

export default Projects;